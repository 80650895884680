import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbCarouselConfig, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-modal2',
  template: `
   <div class="modal-header">
     <button type="button" class="close my-close" aria-label="Close" (click)="close();">
       <span class="close-btn" aria-hidden="true">
          &times;
        </span>
     </button>
    </div>
    <div class="modal-body">
      <ngb-carousel *ngIf="images">
        <ng-template ngbSlide *ngFor="let image of images, index as i">
          <div>
            <img src="{{image}}" class="img-fluid" alt="Random first slide">
          </div>
        </ng-template>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <!-- INSERT MY OWN NEXT ICON USING FONT AWESOME  -->
          <i style="font-size: 60px" class="fas fa-arrow-alt-circle-right"></i>
          <span class="sr-only">Next</span>
        </a>
      </ngb-carousel>
    </div>
  `,
  styles: [`
    .img-fluid {
      height: 80vh;
      min-height: 350px;
      background: no-repeat center center scroll;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  `],
  providers: [NgbCarouselConfig]
})
export class Modal2Component {
  closeResult = '';
  @Input() nome;
  @Input() images;
  @Input() numImages: number;
  @ViewChild('carousel') carousel: any;



  constructor(public activeModal: NgbActiveModal, config: NgbCarouselConfig) {
    // customize default values of carousels used by this component tree
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
    config.interval = 0;
  }

  close(){
    this.activeModal.close();
  }

}

