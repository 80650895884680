import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  template: `
      <section class="page-section about-heading">
          <div class="container">
              <img class="img-fluid rounded about-heading-img mb-3 mb-lg-0" src="../../../assets/about.jpg" alt="">
              <div class="about-heading-content">
                  <div class="row">
                      <div class="col-xl-9 col-lg-10 mx-auto">
                          <div class="bg-faded rounded p-5">
                              <h2 class="section-heading mb-4">
                                  <span class="section-heading-upper">La bellezza sta negli occhi, di chi guarda</span>
                                  <span class="section-heading-lower">Lo scopo?</span>
                              </h2>
                              <p>Il sito è stato costruito da Matteo Ghirelli, esso vuole essere un modo per rendere visibili alcune delle
                                  fotografie di Fabbri Fortunato.</p>
                              <p class="mb-0">Tali fotografie sono state riportate in digitale attraverso l'uso di un obbiettivo fotografico
                                  Macro, per poi essere modificate in Photoshop.
                                  L'uso delle fotografie qui riportate è vietato nel pieno rispetto delle normative del Copyright.</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  `,
  styles: [``]
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
