import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-navbar',
  template: `
  <!-- Navigation -->
  <nav class="navbar navbar-expand-lg navbar-dark py-lg-4" id="mainNav">
    <div class="container">
      <img class="navbar-brand" src="../assets/logo.png" alt="logo" href="#">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"
      (click)="isCollapsed = !isCollapsed">
        <span class="navbar-toggler-icon">
        </span>
      </button>
      <div [ngbCollapse]="!isCollapsed" class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item px-lg-4" [class.active]="router.url==='/'">
            <a class="nav-link text-uppercase text-expanded" href="index.html">Home
              <span class="sr-only">(current)</span>
            </a>
          </li>
          <li class="nav-item px-lg-4" [class.active]="router.url==='/About'">
            <a class="nav-link text-uppercase text-expanded" routerLink="/About">About</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  `,
  styles: [``]
})
export class NavbarComponent implements OnInit {
  active: boolean = false;
  isCollapsed: boolean = false;
  constructor(public router: Router) { }

  ngOnInit(): void {
  }

  clickedLink(e){
    const element = e.srcElement;
    const iter = element.parentElement.parentElement;
    console.log(iter);
    for (let i = 0; i < iter.children.length ; i++){
      if (iter.children[i].classList.contains('active')){
        iter.children[i].classList.remove('active');
      }
    }
    element.parentElement.classList.add('active');

  }
}
