 import {Component, Input, OnInit, ViewChild} from '@angular/core';
 import {ServiceDbService} from '../../service/service-db.service';
 import {FileDb} from '../../interface/file-db';
 import {NgbModal, NgbModalRef, NgbCarouselConfig, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
 import {Modal2Component} from '../../modali/modal2/modal2.component';


 @Component({
   selector: 'app-product',
   template: `
    <section class="page-section">
      <div class="container">
        <div class="product-item">
          <div class="product-item-title d-flex">
            <div class="bg-faded p-5 d-flex ml-auto rounded">
              <h2 class="section-heading mb-0">
                <span class="section-heading-upper">Foto di</span>
                <span class="section-heading-lower">Rifredo</span>
              </h2>
            </div>
          </div>
          <img class="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0" (click)="open('rifredo',1)"
               src="../../../assets/products-01.jpeg" alt="">
          <div class="product-itemdescription d-flex mr-auto">
            <div class="bg-faded p-5 rounded">
              <p class="mb-0">In questa sezione, sono state riportate tutte le foto relative ad eventi e ricordi legati a Rifredo in
                Mugello.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="page-section">
      <div class="container">
        <div class="product-item">
          <div class="product-item-title d-flex">
            <div class="bg-faded p-5 d-flex mr-auto rounded">
              <h2 class="section-heading mb-0">
                <span class="section-heading-upper">Foto di</span>
                <span class="section-heading-lower">Ballo e Spettacoli</span>
              </h2>
            </div>
          </div>
          <img class="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0" (click)="open('ballo',2)"
               src="../../../assets/products-02.jpg" alt="">
          <div class="product-item-description d-flex ml-auto">
            <div class="bg-faded p-5 rounded">
              <p class="mb-0">In questa sezione sono state riportate le fotografie legate ad eventi, relativi a ballo e spettacoli di vario
                tipo.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="page-section">
      <div class="container">
        <div class="product-item">
          <div class="product-item-title d-flex">
            <div class="bg-faded p-5 d-flex ml-auto rounded">
              <h2 class="section-heading mb-0">
                <span class="section-heading-upper">Foto di</span>
                <span class="section-heading-lower">Elementi Generali</span>
              </h2>
            </div>
          </div>
          <img class="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0" (click)="open('general',3)"
               src="../../../assets/products-03.jpg" alt="">
          <div class="product-item-description d-flex mr-auto">
            <div class="bg-faded p-5 rounded">
              <p class="mb-0">In questa sezione sono state inserite le fotografie per cui non è possibile una reale classificazione.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="page-section">
      <div class="container">
        <div class="product-item">
          <div class="product-item-title d-flex">
            <div class="bg-faded p-5 d-flex ml-auto rounded">
              <h2 class="section-heading mb-0">
                <span class="section-heading-upper">Foto di</span>
                <span class="section-heading-lower">Gite</span>
              </h2>
            </div>
          </div>
          <img class="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0" (click)="open('gite',4)"
               src="../../../assets/products-04.jpeg" alt="">
          <div class="product-itemdescription d-flex mr-auto">
            <div class="bg-faded p-5 rounded">
              <p class="mb-0">In questa sezione, sono state riportate tutte le foto relative ad eventi e ricordi legati alle vecchie gite.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="page-section">
      <div class="container">
        <div class="product-item">
          <div class="product-item-title d-flex">
            <div class="bg-faded p-5 d-flex ml-auto rounded">
              <h2 class="section-heading mb-0">
                <span class="section-heading-upper">Foto sulla</span>
                <span class="section-heading-lower">Natura</span>
              </h2>
            </div>
          </div>
          <img class="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0" (click)="open('natura',5)"
               src="../../../assets/products-05.jpg" alt="">
          <div class="product-itemdescription d-flex mr-auto">
            <div class="bg-faded p-5 rounded">
              <p class="mb-0">In questa sezione, sono state riportate tutte le foto relative ad eventi e ricordi legati alla natura.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="page-section">
      <div class="container">
        <div class="product-item">
          <div class="product-item-title d-flex">
            <div class="bg-faded p-5 d-flex ml-auto rounded">
              <h2 class="section-heading mb-0">
                <span class="section-heading-upper">Foto di</span>
                <span class="section-heading-lower">Famiglia</span>
              </h2>
            </div>
          </div>
          <img class="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0" (click)="open('famiglia',6)"
               src="../../../assets/products-06.jpg" alt="">
          <div class="product-itemdescription d-flex mr-auto">
            <div class="bg-faded p-5 rounded">
              <p class="mb-0">In questa sezione, sono state riportate tutte le foto relative ad eventi e ricordi di Famiglia.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
   `,
   styles: [`

  `],
   providers: [NgbCarouselConfig]
 })

 export class ProductComponent implements OnInit {
   modalRef: NgbModalRef;
   urlImage: string;
   nome: string;
   numeroImmagini: number;
   images: string[] = [];
   @ViewChild('carousel') carousel: any;

   constructor( private modalService: NgbModal, private dbReader: ServiceDbService, config: NgbCarouselConfig) {
     config.interval = 0;
     config.keyboard = true;
   }

   ngOnInit(): void {
   }

   open(genere, id) {
     this.nome = genere;
     this.urlImage = this.dbReader.getImage(id);
     this.numeroImmagini = this.dbReader.getNumberImage(id);
     for (let i = 0; i < this.numeroImmagini; i++){
       this.images[i] = (this.urlImage + (i + 1).toString() + '.jpg');
     }
     const modalRef = this.modalService.open(Modal2Component, {size: 'lg', keyboard: true, centered: true});
     modalRef.componentInstance.nome = this.nome;
     modalRef.componentInstance.images = this.images;
     modalRef.componentInstance.numImages = this.numeroImmagini;
     this.images = [];
     this.numeroImmagini = 0;
     this.nome = '';
   }

 }
