import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-heading',
  template: `
    <h1 class="site-heading text-center text-white d-none d-lg-block">
    <span class="site-heading-upper text-primary mb-3">Non si ricordano i giorni, si ricordano gli attimi</span>
    <span class="site-heading-lower"><i class="title"> Antro del Ghiro</i></span>
  </h1>
  `,
  styles: [`
  .title{
    font-family: 'Dancing Script', cursive;
  }`]
})
export class SiteHeadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
