import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <app-site-heading></app-site-heading>
    <app-navbar></app-navbar>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  `,
  styles: [`
  `]
})


export class AppComponent {

  title = 'prog2';



}
