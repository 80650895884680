import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FileDb} from '../interface/file-db';


@Injectable({
  providedIn: 'root'
})
export class ServiceDbService {
  listings: FileDb[] = [];
  list: FileDb[] = [];
  constructor(private http: HttpClient) {
  }

  getImage(id: number) {
    let apiUrl = '';
    if (id === 1) {
      apiUrl = `../assets/Rifredo/`;
    }
    else if(id === 2){
      apiUrl = `../assets/Ballo/`;
    }
    else if(id === 3){
      apiUrl = `../assets/General/`;
    }
    else if(id === 4){
      apiUrl = `../assets/Gite/`;
    }
    else if(id === 5){
      apiUrl = `../assets/Nature/`;
    }
    else if(id === 6){
      apiUrl = `../assets/Famiglia/`;
    }
    return apiUrl;
  }
  getNumberImage(id: number) {
    if (id === 1) {
      return 67;
    }
    else if (id === 2){
      return 4;
    }
    else if (id === 3){
      return 61;
    }
    else if (id === 4){
      return 48;
    }
    else if (id === 5){
      return 155;
    }
    else if (id === 6){
      return 450;
    }
  }

}
