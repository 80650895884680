import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {SiteHeadingComponent} from './shared/site-heading/site-heading.component';
import {NavbarComponent} from './shared/navbar/navbar.component';
import {FooterComponent} from './shared/footer/footer.component';
import {AboutComponent} from './componenti/about/about.component';
import {ProductComponent} from './componenti/product/product.component';
import {Modal2Component} from './modali/modal2/modal2.component';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {CarouselModule} from 'ngx-bootstrap/carousel';

@NgModule({
  declarations: [
    AppComponent,
    SiteHeadingComponent,
    NavbarComponent,
    FooterComponent,
    AboutComponent,
    ProductComponent,
    Modal2Component,
  ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        NgbModule,
        NgxSmartModalModule.forRoot(),
        CarouselModule
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
