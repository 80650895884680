import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AboutComponent} from './componenti/about/about.component';
import {ProductComponent} from './componenti/product/product.component';


const routes: Routes = [
  { path: '', component: ProductComponent },
  { path: 'Products', component: ProductComponent },
  { path: 'About', component: AboutComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
